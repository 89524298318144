import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import ProjectSection from "../components/ProjectSection";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import LinkArrowSvg from "../images/link-arrow";
import Reports from "../components/hire/Reports";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import OurWorkslef from "../components/hire/OurWorkself";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire UI UX Designer`,
    text: "Get Outcome Driven Designers",
    content: "Revolutionize your websites and products by hiring UI UX Designers from Octet. Our dedicated UI UX designers conduct in-depth research and create innovative user experience designs.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Designer',
      'Time-Zone Friendly'
    ],
    linkText: 'Hire UI UX Designer',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'Hire Dedicated UI UX Designers',
    text: "Whether you are rebuilding an old product or launching a new one, our talented UI UX designer creates memorable experiences that increase sales.",
    list: [
      {
        title: 'Hire UX Designer',
        text: 'Our outcome-focused UX designers analyze user insights using both qualitative and quantitative techniques. They provide engaging digital content and boost user satisfaction.'
      },
      {
        title: 'Hire UI Designer',
        text: 'Our principle-backed UI designers create functional and customized user interfaces using design principles, programming languages, and industry knowledge.'
      },
      {
        title: 'Hire UI UX Consultants',
        text: 'Our UI UX designer for hire creates user-friendly interfaces that provide a positive user experience by prioritizing consistency, simplicity, hierarchy, feedback, and affordances.'
      }
    ],
    image: ["hire-content.webp"],
    titleText: 'Hire UI UX Designer from Octet',
    alt: 'Expertise of our UI UX Designers for Hire'
  }
  const commonContent2 = {
    title: 'Get Octet’s Top UI UX Designer for hire',
    text: "Leading companies trust Octet Design Studio to Hire UI UX designers and developers to acquire top design expertise with industry-specific experience.",
    list: [
      {
        title: 'Hire UI UX Developer In 48 Hours',
        text: 'Our top UI UX designers for hire ensure swift recruitment processes, bringing aboard and hiring the best UX designers within 48 hours.'
      },
      {
        title: 'Transparent Reporting',
        text: 'Stay in control with detailed timesheet reports from our UI UX designers to track progress and productivity.'
      },
      {
        title: '160 hours of Dedicated Work',
        text: 'Our UI UX Designer for hire commits 160 hrs of focused, high-quality work, ensuring productivity and results.'
      },
      {
        title: 'Seamless Collaboration',
        text: 'Hire UX designer and enjoy seamless collaboration with a dedicated manager who oversees your design needs and ensures a smooth workflow.'
      }
    ],
    image: ["hire-content-2.webp"],
    titleText: 'Benefits of UI UX Designer',
    alt: 'Benefits of Hiring UI UX Designer from our Design Studio'
  }
  const commonContent3 = {
    title: `3 Steps to Hire UI UX Designer`,
    text: "Experience the Octet advantage by hiring creative UI UX designers in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: "Provide us with your project requirements, and we will share the resumes of our UI UX designers and developers who best match your needs and business goals."
      },
      {
        title: 'Step 2: Interview Designers',
        text: "Interview and evaluate our skilled UX Designers to choose the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire UI UX designer, onboard them in the next 48 hours and dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-3.webp"],
    titleText: 'Steps to Hire UI UX Designers',
    alt: '3 easy steps to Hire our UI UX Designer'
  }
  // const commonContent4 = {
  //   title: 'Hire Designers As Per Your Requirement',
  //   text: "At Octet, you have the freedom to choose your designer with flexible recruitment options. Select the hiring model that best suits your needs.",
  //   list: [
  //     {
  //       title: 'Hire on Fixed Cost Basis',
  //       text: "Get your project completed within a predetermined budget, ensuring cost-effectiveness and transparency."
  //     },
  //     {
  //       title: 'Hire on Time & Material Basis',
  //       text: "Flexibility is key with this model, pay for the actual hours worked and materials used, ideal for dynamic projects."
  //     },
  //     {
  //       title: 'Hire as a Full-time',
  //       text: "Secure a dedicated designer for ongoing projects, providing consistent support and expertise as part of your team."
  //     },
  //   ],
  //   image: ["hire-content-4.webp"],
  //   titleText: 'Hire UI UX Designer Flexibly',
  //   alt: 'Flexible Hiring Models to Hire UI UX Designers from our design agency'
  // }
  const cta = {
    title: `Hire UI UX Designer within <span class="h1-span">48 hours!</span>`,
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "eCommerce UI UX Designers",
      content: "Our UX designers use various tools and software to create wireframes, prototypes, and designs that meet users' needs and help convert visitors into customers. Hire UI UX designer to create an enjoyable and seamless shopping experience for customers, which can increase sales and customer loyalty.",
    },
    {
      title: "UI UX Designers for SaaS",
      content:
        "SaaS app UX Designers specializing in SaaS platforms create visually appealing and user-friendly interfaces for web and mobile applications. Hiring dedicated UX developers will prove to be creative problem solvers to create beautiful and functional software that enhances the user experience.",
    },
    {
      title: "Enterprise UI UX experts",
      content: "Hiring UI UX designers will allow you to deliver scalable solutions for large-scale operations. These designers will address intricate workflows and compliance needs with precision and user-centric design. Our UX design experts will work closely with developers and product managers to ensure the final product meets the enterprise's and its users' needs.",
    },
  ];
  const tabsData = {
    title: `Hire UI UX Designer with Diverse Expertise`,
    des: `Our designers bring experience and expertise to help you implement innovative solutions with a design-first approach. Hire UI and UX developers from Octet as per your business needs.`,
    tabs: ["Mobile App Designing ", "Website Designing", "Mobile-first Designing", "Desktop App Designing"],
    slides: [
      {
        title: 'Mobile App Designing',
        desc1: "Get creative mobile app designs with our UI UX designers for hire, who specialize in mobile app interactions and focus on seamless, multi-platform user experiences.",
        desc2: 'Hire UI designer for mobile app designing to obtain consistent user-centric design, better accessibility, and intuitive layouts.',
        list: [
          'Experienced in mobile app design patterns',
          'Expertise in platform-specific design guidelines',
          'Ability to deliver in quick turnaround'
        ]
      },
      {
        title: 'Website Designing',
        desc1: "Hire expert UI UX designer and developer for website to craft dynamic, adaptable, and intuitive web interfaces specific to platforms.",
        desc2: 'Enhance your brand’s online presence with a focus on user-friendly, innovative design solutions tailored to your unique web UI UX needs.',
        list: [
          'Experienced in brand designing',
          'Delivering designs that convert leads',
          'Mastery of storytelling through designs'
        ]
      },
      {
        title: 'Mobile-first Designing',
        desc1: "Get our UI UX designers for hire to get creative designs optimized for smaller screens of mobile devices.",
        desc2: 'Our UI UX experts create visually appealing mobile designs that ensure conciseness and efficiency to deliver a better user experience.',
        list: [
          'Delivering designs for higher conversions',
          'Expertise in creating high performing designs',
          'Accessibility approach to make inclusive product'
        ]
      },
      {
        title: 'Desktop App Designing',
        desc1: "Hiring a UI UX designer for desktop app designing is crucial to creating a high-quality product that stands out in the market and delivers an exceptional user experience.",
        desc2: "Our UI UX designers will create workflows and optimize user experience for desktop devices to meet users' needs and expectations.",
        list: [
          'Expertise in creating real-world experiences',
          'Aligned to desktop usability standards',
          'Experienced in rapid prototyping techniques'
        ]
      }
    ],
    altOne: 'Mobile App Design by our UI UX Designer for Hire',
    titleOne: 'Mobile App Design',
    altTwo: 'Hire UI UX Designer to create web app design',
    titleTwo: 'Web App Design',
    altThree: 'UI UX Designer for Hire creating responsive website designs',
    titleThree: 'Responsive Website Design',
    altFour: 'Hire UI UX Designer to create wireframes and prototypes',
    titleFour: 'Wireframing and Prototypes',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "Simplifying Crew Management Process",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"]
    },
    {
      title: "Survey2Connect",
      desc: "Streamlining Survey Collection Solution",
      Industry: "Enterprise",
      firstAlt: "UI UX Design To Create Survey",
      firstTitle: "Survey Creation Screen",
      secondAlt: "Ui Design To Create Questionnaire For Survey",
      secondTitle: "Questionnaire For Survey",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp", "survey-3.webp"],
    },
    {
      title: "Elastic Sign",
      desc: "Efficient Design for Secure Document Signing",
      Industry: "SaaS",
      firstAlt: "UI Design to View all the Documents and their Status",
      firstTitle: "Manage Documents UI Design",
      secondAlt: "UI UX Design to Sign Documents Digitally",
      secondTitle: "UI Design to Sign Digital Documents",
      thirdAlt: "UI Screen to draw, upload or type your own signature",
      thirdTitle: "Create Your Own Signature",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"]
    },
    {
      title: "Unilogix App",
      desc: "Comprehensive Logistic Analytics Suite",
      Industry: "Logistics",
      firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
      firstTitle: "Logistics Services List",
      secondAlt: "Order And Warehouse Details",
      secondTitle: "Order Details",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp", "uni-logics-3.webp"],
    },
    {
      title: "Ship Delight",
      desc: "User-Centric Logistic Website Redesign",
      Industry: "Logistics",
      firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "UI Dashboard To Check The Performance Status",
      secondTitle: "NDR Status",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp", "ship-delight-web-3.webp"],
    },
  ];
  const lastLength = projectData;
  const itemsList = [
    {
      title: '200+',
      text: 'Successful Projects '
    },
    {
      title: '30+',
      text: 'Experienced Designers'
    },
    {
      title: '80+',
      text: 'Satisfied Clients'
    }
  ];
  const reports = {
    title: `Hire Designers from <span class="h1-span">Octet vs Others</span>`,
    text: "Choosing Octet means efficient execution and a speedier ROI. Our UI UX designers for hire are intensely passionate and committed to creating captivating designs and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s UI UX Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'SaaS Design Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const ourData = {
    title: 'Hear From Our Clients',
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['visa.webp'],
        stars: 4,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      }
    ]
  };
  const Questions = [
    "Why should you hire us as UI UX Designer?",
    "How can you hire UI UX designer from Octet?",
    "Which Companies Hire UI UX Designer?",
    "Does hiring UX designers come with a trial period?",
    "How does our transparent pricing system work?"
  ];
  const faqData = [
    {
      para: [
        `Octet boasts a skilled, creative, and functional team of UI UX designers who specialize in crafting engaging and functional interfaces.`,
        `Our UI and UX designers have a proven track record of delivering user-centric designs across diverse industries. They are well-versed in UI and UX principles and keep themselves updated with the latest design technologies and trends.`,
        `You should hire UI UX Designers from Octet to ensure your product will remain relevant and competitive.`
      ]
    },
    {
      para: [
        `Hiring UI UX Designers from Octet involves just 3 easy steps:`,
        `<strong><h4>Share Your Requirements:</h4></strong>`,
        `Tell us about your project needs, including the scope, objectives, target audience, and specific design preferences or technical requirements. This will help us find the most suitable UI UX designer to meet your needs and expectations.`,
        `<strong><h4>Interview Designers:</h4></strong>`,
        `Once we understand your needs, we will share the list of UI UX designers and developers matching your project needs.`,
        `You can then interview and evaluate our designers to assess their skills, experience, and creativity.`,
        `Choose the candidate who best matches your project goals.`,
        `<strong><h4>Onboard and Begin:</h4></strong>`,
        `After hiring UI UX designers from us, onboard our designers onto your project team and provide them with access to necessary resources.`,
        `Communicate project timelines, milestones, and expectations to ensure a smooth transition.
        Once onboarded, collaborate closely with our designer to refine concepts, iterate on designs, and ultimately bring your project to life.`,
      ]
    },
    {
      para: [
        `UI UX designers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.`,
        `Whether it's designing mobile apps, websites, software interfaces, or consumer electronics, our UI UX designers for hire can contribute to enhancing usability, functionality, and visual appeal for different companies.`,
      ]
    },
    {
      para: [
        `Yes, hiring UX Designers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company.`,
      ]
    },
    {
      para: [
        `At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services.`,
        `Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring UI and UX Designers from us, you can trust that you're getting the most value for your money.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Hire UI UX developer for people-centric designing",
    para: 'Our creative team of UI UX designers for hire provides a combination of design services across digital product-user touchpoints to deliver solutions with significant commercial impact.',
    content: [
      {
        title: "UX Consultation",
        text: "Hiring UX consultants will provide beneficial UX consultation strategies suited to your business needs. Our UX experts conduct extensive user research and develop strategies to provide tailored recommendations for long-term growth.",
      },
      {
        title: "Usability Testing",
        text: "Whether you are a startup or an MNC, usability testing offers valuable insights into improving customer satisfaction and assuring business success. Hiring UI UX experts will simplify usability testing processes and enable you to bring fresh perspectives.",
      },
      {
        title: "UI UX Designing",
        text: "Our UX UI designers specialize in creating digital experiences across several industries. Hire UI UX specialists to understand your user, conduct in-depth analysis, and gain custom design solutions for increased business success.",
      },
      {
        title: "Wireframes & Rapid Prototype",
        text: "Rapid Prototyping enables you to experiment and alter your products through early iterations, resulting in more informed decisions, better features, and lower risks. Our UI UX developers for hire will help you build promising products.",
      },
      {
        title: "Design System Engineering",
        text: "With our top UX professionals in design system engineering, you can conduct detailed research to comprehend your company's users and build comprehensive design components and systems.",
      },
      {
        title: "Design Audit",
        text: "Hiring top-rated UI UX designers will allow you to examine the visual parts of the design thoroughly. Our designers will provide the best UX audit services to assist you in gaining valuable insights and offering optimum usability.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why should you hire us as UI UX Designer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet has a team of highly skilled design professionals who believe in crafting experiences, focusing on creating engaging and functional interfaces.
          
          With a proven record of delivering user-centric designs across various industries, our team brings expertise in both UI and UX principles. Our UI UX designers also stay abreast of the latest UI UX trends and design technologies, ensuring your product remains relevant and competitive."
              }
            },{
              "@type": "Question",
              "name": "How to hire a UI UX designer from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring UI UX Designers from Octet is quite simple and involves 3 easy steps:
          
          Share Your Requirements:
          
          Tell us about your project needs, including the scope, objectives, target audience, and specific design preferences or technical requirements. This will help us find the most suitable UI UX designer to meet your needs and expectations.
          
          Interview Designers:
          
          Once we understand your needs, we will share the list of UI UX designers matching your project needs.
          
          You can then interview and evaluate our designers to assess their skills, experience, and creativity.
          
          Choose the candidate who best matches your project goals.
          
          Onboard and Begin:
          
          After hiring UI UX designers from us, onboard our designers onto your project team and provide them with access to necessary resources.
          
          Communicate project timelines, milestones, and expectations to ensure a smooth transition. Once onboarded, collaborate closely with our designer to refine concepts, iterate on designs, and ultimately bring your project to life."
              }
            },{
              "@type": "Question",
              "name": "Which Companies Hire UI UX Designers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "UI UX designers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, and telecommunications.
          
          Whether it's designing mobile apps, websites, software interfaces, or consumer electronics, our UI UX designers can contribute to enhancing usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Does hiring a designer come with a trial period?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our team has a deep understanding of the importance of finding the perfect fit for any job. To ensure that our clients are satisfied with our services, we offer a complimentary 3-day trial.
          
          This provides an opportunity for our clients to experience our approach and see how we can help them find the right candidate for their company."
              }
            },{
              "@type": "Question",
              "name": "How does our transparent pricing system work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our company, we believe in transparency when it comes to pricing. We understand the importance of knowing exactly what you're paying for without any surprises. That's why we offer a clear breakdown of costs for all our products and services.
          
          Our pricing model is designed to eliminate hidden fees and provide you with customizable solutions that are tailored to your unique needs. With us, you can trust that you're getting the most value for your money."
              }
            }]
          }                            
        `}
      </script>
    </Helmet>
      <Layout hire="Hire Our UI UX Experts">
        <div>
          <HireBanner data={banner} pageName="Hire UI/UX Designer" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Hire Dedicated UI UX Designer <span class="h1-span">for your Industry needs</span>`} text={``} />
          <ContentCommon data={commonContent3} big reverse />
          {/* <ContentCommon data={commonContent4} big /> */}
          {/* <OurWorkslef data={ourData} /> */}
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} des={'Explore common queries about our UI UX designing services, hiring UI UX designers and developers, pricing, and processes for clarity and confidence in your decision-making journey.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;
export const Head = () => (
  <Seo
    title="Hire UI UX Designers - Get 3 Days Free Trial"
    description="Looking to hire UI UX designers? Try our creative UI UX designers on a 3-day free trial to ensure they are the right fit for the job. Get started today."
  />
);